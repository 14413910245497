import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { FiFolder } from 'react-icons/fi';

import PageTitle from '../../../components/PageTitle';
import ActionButton from '../../../components/ActionButton';
import Card from '../../../components/Card';
import Button from '../../../components/Button';
import ActionArea from '../../../components/ActionArea';
import ControllerPage from '../../../components/ControllerPage';

import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';

import { Container } from './styles';

import api from '../../../services/api';

interface Permission {
  id: string;
  name: string;
  description: string;
}

interface UserPermissionProps {
  id: string;
  name: string;
}

const Permission: React.FC = () => {
  const breadcrumbs = [{ title: 'Permissões', to: '' }];

  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { user }: any = useAuth();

  const [permissions, setPermissions] = useState<Permission[]>([]);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const [loadingDelete, setLoadingDelete] = useState(false);

  // delete
  const handleDelete = useCallback(
    async id => {
      setLoadingDelete(true);

      removeModal();

      try {
        await api.delete(`permissions/${id}`);

        setPermissions(state =>
          state.filter(permission => permission.id !== id),
        );

        setLoadingDelete(false);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Permissão deletada com sucesso!',
        });
      } catch (err) {
        setLoadingDelete(false);

        addToast({
          type: 'error',
          title: 'Erro ao deletar a permissão',
          description: 'Ocorreu um erro ao tentar deletar essa permissão.',
        });
      }
    },
    [addToast, removeModal],
  );

  const handleDeleteModal = useCallback(
    id => {
      addModal({
        title: 'Deletar Permissão',
        text: 'Deseja realmente deletar esta permissão?',
        confirm: true,
        onConfirm: () => handleDelete(id),
        onCancel: removeModal,
      });
    },
    [addModal, removeModal, handleDelete],
  );

  // loading data list
  useEffect(() => {
    api.get(`permissions?page=${page}`).then(response => {
      setTotalPage(20); // response.data.totalPage
      setPermissions(response.data.data);
    });
  }, [page]);

  // permissão
  const [hasPermission, setHasPermission] = useState<UserPermissionProps[]>([]);

  useEffect(() => {
    if (user) {
      // const userParsed = JSON.parse(user);

      // TODO: get the permission in the backend
      // userParsed?.roles[0]?.permissions;
      const userPermissions: UserPermissionProps[] = [
        { id: '1', name: 'permission-list' },
        { id: '2', name: 'permission-create' },
        { id: '3', name: 'permission-edit' },
        { id: '4', name: 'permission-delete' },
      ];

      let permissionsPage: any;

      if (JSON.parse(user)?.roles[0]?.id === 1) {
        permissionsPage = [
          'permission-list',
          'permission-create',
          'permission-edit',
          'permission-delete',
        ];
      } else {
        permissionsPage = ['permission-list'];
      }
      // 'permission-create',
      // 'permission-edit',
      // 'permission-delete',

      if (permissionsPage) {
        const result: UserPermissionProps[] = userPermissions.filter((x: any) =>
          permissionsPage?.includes(x.name),
        );
        setHasPermission(result);
      }
    }
  }, [user]);
  // ------------

  return (
    <Container>
      <PageTitle
        icon={FiFolder}
        title="Lista de Permissões"
        description="Abaixo está listado as permissões já registrados"
        breadcrumbs={breadcrumbs}
      />
      <ActionArea>
        <div>
          {hasPermission?.map(
            (x: any) =>
              x.name.indexOf('create') !== -1 && (
                <Button>
                  <Link to="/permissions/add">Nova Permissão</Link>
                </Button>
              ),
          )}
        </div>
        <div />
      </ActionArea>
      <Card title="Lista de Permissões">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Nome</th>
              {/* <th>Tipo</th> */}
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            {permissions.map((permission, index) => (
              <tr key={permission.id}>
                <td>{index + 1 + (page - 1) * 20}</td>
                <td>{permission.name}</td>
                {/* <td>
                  <Badge type={user.system === 1 ? 'system' : 'app'}>
                    {user.system === 1 ? 'Sistema' : 'Aplicativo'}
                  </Badge>
                </td> */}
                <td>
                  <ActionButton
                    id={permission.id}
                    path="/permissions"
                    onDelete={() => handleDeleteModal(permission.id)}
                    hasPermission={hasPermission}
                    loadingDelete={loadingDelete}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ControllerPage>
          {page !== 1 ? (
            <Button onClick={() => setPage(page - 1)}>Página anterior</Button>
          ) : (
            <div />
          )}

          {totalPage > page && (
            <Button onClick={() => setPage(page + 1)}>Próxima página</Button>
          )}
        </ControllerPage>
      </Card>
    </Container>
  );
};

export default Permission;

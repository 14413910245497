import React, { ButtonHTMLAttributes } from 'react';
import Loader from 'react-loader-spinner';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  outline?: boolean;
  loading?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  children,
  outline,
  loading,
  ...rest
}) => (
  <Container
    type="button"
    outline={outline}
    disabled={loading}
    loading={loading}
    {...rest}
  >
    {loading ? (
      <Loader
        type="ThreeDots"
        color={outline ? '#0F355D' : '#fff'}
        height={25}
        width={25}
        timeout={0}
      />
    ) : (
      <>{children}</>
    )}
  </Container>
);

export default Button;

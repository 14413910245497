import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 1.5rem 0px;

  display: flex;
  justify-content: space-between;

  button {
    height: 35px;
    width: auto;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }
`;

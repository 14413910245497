import React from 'react';
import { FiX } from 'react-icons/fi';
import { useTransition } from 'react-spring';

import Button from '../Button';

import { Container, Content, Header, Body, Footer } from './styles';

import { ModalProps } from '../../hooks/modal';

interface ModalContainerProps {
  data: ModalProps;
}

const Modal: React.FC<ModalContainerProps> = data => {
  const transitions = useTransition(data.data, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  return (
    <Container>
      {transitions.map(({ item, key, props }) => (
        <Content key={key} style={props}>
          <Header>
            <h5>{item.title}</h5>
          </Header>
          <Body>
            <p>{item.text}</p>
          </Body>
          <Footer>
            {item.confirm ? (
              <>
                <Button type="button" onClick={item.onCancel}>
                  Não
                </Button>
                <Button type="button" onClick={item.onConfirm}>
                  Sim
                </Button>
              </>
            ) : (
              <button type="button" onClick={item.onConfirm}>
                Entendi
              </button>
            )}
          </Footer>
          <button type="button" onClick={item.onCancel}>
            <FiX size={20} />
          </button>
        </Content>
      ))}
    </Container>
  );
};

export default Modal;

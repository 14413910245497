import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  background: ${props => props.theme.colors.pageTitleBackground};
  padding: 30px;
  margin: -30px -30px 0px;
  position: relative;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    font-size: 1.25rem;
    font-weight: 400;
    display: flex;
    align-content: center;
    align-items: center;
  }
`;

export const TitleIcon = styled.div`
  font-size: 2rem;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 0.83333rem;
  margin: 0 30px 0 0;
  background: #fff;
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
    0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
    0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
    0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
  border-radius: 0.25rem;
  width: 60px;
  height: 60px;

  i {
    display: inline-block;
    font-family: Pe-icon-7-stroke;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    color: ${props => props.theme.colors.pageTitleIcon};
    margin: auto;
  }
`;

export const TitleText = styled.div`
  h1 {
    font-size: 1.25rem;
    font-weight: 400;
  }

  p {
    padding: 3px 0 0;
    font-size: 0.88rem;
    opacity: 0.6;
  }
`;

export const Breadcrumbs = styled.div`
  nav {
    display: block;
    font-size: 14px;

    ol {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      border-radius: 0.25rem;

      margin: 0;
      padding: 0;
      background: transparent;

      li {
        & + li {
          padding-left: 0.5rem;

          &::before {
            display: inline-block;
            padding-right: 0.5rem;
            color: #6c757d;
            content: '/';
          }
        }
      }
    }
  }

  a {
    outline: none !important;
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
    background-color: initial;
    transition: color 0.2s;

    &:hover {
      color: ${props => shade(0.2, `${props.theme.colors.primary}`)};
    }
  }
`;

import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused?: boolean;
  isFilled?: boolean;
  isErrored?: boolean;
  disabled?: boolean;
}

export const Container = styled.div<ContainerProps>`
  /* color: ${props => props.theme.colors.primary};
  background: ${props => props.theme.colors.text};
  border-radius: 10px;
  border: 2px solid ${props => props.theme.colors.inputBorderColor};
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      color: ${props.theme.colors.primary};
      border-color: ${props.theme.colors.primary};
    `}

  ${props =>
    props.isFilled &&
    css`
      color: ${props.theme.colors.inputPlaceholder};
    `}

  ${props =>
    props.disabled &&
    css`
      background-color: ${props.theme.colors.inputDisabled};
      border-color: ${props.theme.colors.inputDisabled};
    `} */

    .select__control {
      border-radius: 10px;
      border: 2px solid ${props => props.theme.colors.inputBorderColor};
      box-shadow:white;

    }
    .select__menu{
      z-index: 999;
      margin-bottom: 60px;
    }

    & .select__value-container{
    color: ${props => props.theme.colors.primary};
      background: ${props => props.theme.colors.inputBackground};
      border-radius: 10px;
      border: 2px solid ${props => props.theme.colors.inputBorderColor};
      padding: 8px;
      width: 100%;
      display: flex;
      align-items: center;


    &::placeholder {
      color: ${props => props.theme.colors.inputPlaceholder};
    }
  }

  .s

  svg {
    margin-right: 16px;
  }
`;

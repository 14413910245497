import styled, { css } from 'styled-components';

interface ActionProps {
  type?: 'info' | 'edit' | 'delete';
  visible?: boolean;
  loading?: boolean;
}

const actionTypeVariations = {
  info: css`
    background: #30b1ff;
    border-color: #30b1ff;
  `,
  edit: css`
    background: #3ac47d;
    border-color: #3ac47d;
  `,
  delete: css`
    background: #d92550;
    border-color: #d92550;
    /* opacity: 0.7; */
  `,
};

export const Container = styled.div`
  display: flex;
`;

export const Action = styled.div<ActionProps>`
  a,
  button {
    /* display: flex; */
    display: ${props => (props.visible ? 'none' : 'flex')};
    align-items: center;
    justify-content: center;

    width: 30px;
    height: 30px;
    border-radius: 4px;
    border: 0;

    opacity: ${props => (props.loading ? 0.7 : 1)};

    color: #fff;

    ${props => actionTypeVariations[props.type || 'info']};

    transition: color 0.15s, background-color 0.15s, border-color 0.15s,
      box-shadow 0.15s;
  }

  & + div {
    margin-left: 5px;
  }
`;

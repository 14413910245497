import React from 'react';

import { Container, CardBody, CardTitle } from './styles';

interface Props {
  title?: string;
}

const Application: React.FC<Props> = ({ title, children }) => {
  return (
    <Container>
      <CardBody>
        {title && <CardTitle>{title}</CardTitle>}
        {children}
      </CardBody>
    </Container>
  );
};

export default Application;

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiFolder } from 'react-icons/fi';
import * as Yup from 'yup';

import PageTitle from '../../../../components/PageTitle';
import Card from '../../../../components/Card';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import ActionArea from '../../../../components/ActionArea';

import getvalidationErrors from '../../../../utils/getvalidationsErrors';

import { useToast } from '../../../../hooks/toast';

import { Container } from './styles';

import api from '../../../../services/api';

interface PermissionProps {
  id: string;
  name: string;
  description: string;
}

interface PageDataProps {
  info: {
    title: string;
    description: string;
  };
  add: {
    title: string;
    description: string;
  };
  edit: {
    title: string;
    description: string;
  };
}

interface SubmitFormData {
  name: string;
}

const Permission: React.FC = () => {
  const pageData: PageDataProps = {
    info: {
      title: 'Visualizando Permissão',
      description:
        'Abaixo está disponível as informações da permissão selecionada.',
    },
    add: {
      title: 'Criando Permissão',
      description: 'Insira os dados abaixo para criar nova permissão.',
    },
    edit: {
      title: 'Editando Permissão',
      description:
        'Abaixo está disponível as informações da permissão selecionada.',
    },
  };

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const params = useParams<{ type: string; id: string }>();
  const type: keyof PageDataProps = params.type as keyof PageDataProps;
  const { id } = params;

  const breadcrumbs = [
    { title: 'Permissões', to: '' },
    { title: `${pageData[type].title}`, to: '' },
  ];

  const [permission, setPermission] = useState<PermissionProps>();

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  // loading data
  useEffect(() => {
    if (type === 'add') {
      return;
    }

    api.get(`permissions/${id}`).then(response => {
      setPermission(response.data);
    });
  }, [id, type]);

  // submit addd/edit
  const handleSubmit = useCallback(
    async (data: SubmitFormData) => {
      try {
        setLoadingSubmit(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O nome da permissão é obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        if (type === 'add') {
          await api.post('permissions', data);
        } else if (type === 'edit') {
          await api.put(`permissions/${id}`, data);
        }

        history.push('/permissions');

        setLoadingSubmit(false);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `${
            type === 'add' ? 'Cadastro' : 'Alteração'
          } realizada com sucesso`,
        });
      } catch (err) {
        setLoadingSubmit(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getvalidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro durante o processo',
          description: 'Ocorreu um erro ao realizar a operação.',
        });
      }
    },
    [type, addToast, history, id],
  );

  return (
    <Container>
      <PageTitle
        icon={FiFolder}
        title={pageData[type].title}
        description={pageData[type].description}
        breadcrumbs={breadcrumbs}
      />
      <ActionArea>
        <div>
          {(type === 'edit' || type === 'add') && (
            <Button type="submit" form="form" loading={loadingSubmit}>
              Salvar
            </Button>
          )}
        </div>
        <div>
          <Button outline>
            <Link to="/permissions">Voltar</Link>
          </Button>
        </div>
      </ActionArea>
      <Card>
        <Form
          id="form"
          onSubmit={handleSubmit}
          initialData={permission}
          ref={formRef}
        >
          <label htmlFor="name">Nome</label>
          <Input
            id="name"
            type="text"
            name="name"
            title="Nome"
            placeholder="Nome da permissão"
            disabled={type === 'info'}
          />
        </Form>
      </Card>
    </Container>
  );
};

export default Permission;

import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { FiFolder } from 'react-icons/fi';

import PageTitle from '../../components/PageTitle';
import ActionButton from '../../components/ActionButton';
import Card from '../../components/Card';
import Button from '../../components/Button';
import ActionArea from '../../components/ActionArea';

import { useModal } from '../../hooks/modal';
import { useToast } from '../../hooks/toast';

import { Container } from './styles';

import api from '../../services/api';
import ControllerPage from '../../components/ControllerPage';

import { useAuth } from '../../hooks/auth';

interface Testimony {
  id: string;
  name: string;
}

interface UserPermissionProps {
  id: string;
  name: string;
}

const Testimonies: React.FC = () => {
  const breadcrumbs = [{ title: 'Depoimentos', to: '' }];

  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();

  const [testimonies, setTestimonies] = useState<Testimony[]>([]);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const { user }: any = useAuth();

  const [loadingDelete, setLoadingDelete] = useState(false);

  // Delete
  const handleDelete = useCallback(
    async id => {
      setLoadingDelete(true);

      removeModal();

      try {
        await api.delete(`testimonies/${id}`);

        setTestimonies(state => state.filter(area => area.id !== id));

        setLoadingDelete(false);
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Depoimento deletado com sucesso!',
        });
      } catch (err) {
        setLoadingDelete(false);
        addToast({
          type: 'error',
          title: 'Erro ao deletar um depoimento',
          description: 'Ocorreu um erro ao tentar deletar esse depoimento.',
        });
      }
    },
    [addToast, removeModal],
  );

  const handleDeleteModal = useCallback(
    id => {
      addModal({
        title: 'Deletar Depoimento',
        text: 'Deseja realmente deletar este depoimento?',
        confirm: true,
        onConfirm: () => handleDelete(id),
        onCancel: removeModal,
      });
    },
    [addModal, removeModal, handleDelete],
  );

  // loading testimonies
  useEffect(() => {
    api.get(`testimonies?page=${page}`).then(response => {
      setTotalPage(response.data.totalPage);
      setTestimonies(response.data.data);
    });
  }, [user, page]);

  // permissão
  const [hasPermission, setHasPermission] = useState<UserPermissionProps[]>([]);

  useEffect(() => {
    if (user) {
      // const userParsed = JSON.parse(user);

      // TODO: get the permission in the backend
      // userParsed?.roles[0]?.permissions;
      const userPermissions: UserPermissionProps[] = [
        { id: '1', name: 'testimony-list' },
        { id: '2', name: 'testimony-create' },
        { id: '3', name: 'testimony-edit' },
        { id: '4', name: 'testimony-delete' },
      ];

      const permissions = [
        'testimony-list',
        'testimony-create',
        'testimony-edit',
        'testimony-delete',
      ];

      if (permissions) {
        const result: UserPermissionProps[] = userPermissions.filter((x: any) =>
          permissions?.includes(x.name),
        );
        setHasPermission(result);
      }
    }
  }, [user]);

  return (
    <Container>
      <PageTitle
        icon={FiFolder}
        title="Lista de depoimentos"
        description="Abaixo está listado as depoimentos já registradas"
        breadcrumbs={breadcrumbs}
      />
      <ActionArea>
        <div>
          {hasPermission?.map(
            (x: any) =>
              x.name.indexOf('create') !== -1 && (
                <Button>
                  <Link to="/testimonies/add">Novo Depoimento</Link>
                </Button>
              ),
          )}
        </div>
        <div />
      </ActionArea>
      <Card title="Lista de Depoimentos">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Nome</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            {testimonies.map((testimony, index) => (
              <tr key={testimony.id}>
                <td>{index + 1 + (page - 1) * 20}</td>
                <td>{testimony.name}</td>
                <td>
                  <ActionButton
                    id={testimony.id}
                    path="/testimonies"
                    onDelete={() => handleDeleteModal(testimony.id)}
                    hasPermission={hasPermission}
                    loadingDelete={loadingDelete}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ControllerPage>
          {page !== 1 ? (
            <Button onClick={() => setPage(page - 1)}>Página anterior</Button>
          ) : (
            <div />
          )}

          {totalPage > page && (
            <Button onClick={() => setPage(page + 1)}>Próxima página</Button>
          )}
        </ControllerPage>
      </Card>
    </Container>
  );
};

export default Testimonies;

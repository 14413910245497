import React, { useContext } from 'react';

import { ThemeContext } from 'styled-components';

import { FiPower, FiMenu } from 'react-icons/fi';
import { useAuth } from '../../hooks/auth';

import { Container, HeaderLogo, Content, LogoutButton } from './styles';

const Header: React.FC = () => {
  const { headerLogo } = useContext(ThemeContext);

  const { signOut } = useAuth();

  async function handleSubmit() {
    await signOut();
  }

  return (
    <Container>
      <HeaderLogo>
        <img src={headerLogo} alt="Header Logo" />
        <FiMenu size={26} />
      </HeaderLogo>
      <Content>
        <div />
        <div>
          <LogoutButton onClick={handleSubmit} style={{ height: '30px' }}>
            <FiPower size={20} />
          </LogoutButton>
        </div>

        {/* <div>
          <Switch
            onChange={() => {}}
            checked
            checkedIcon={false}
            uncheckedIcon={false}
            height={10}
            width={40}
            handleDiameter={20}
            offColor={shade(0.2, colors.primary)}
            onColor={colors.secondary}
          />
        </div> */}
      </Content>
    </Container>
  );
};

export default Header;

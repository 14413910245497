import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiFolder } from 'react-icons/fi';
import * as Yup from 'yup';

import PageTitle from '../../../components/PageTitle';
import Card from '../../../components/Card';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import ActionArea from '../../../components/ActionArea';
import Select from '../../../components/Select';

import getvalidationErrors from '../../../utils/getvalidationsErrors';

import { useToast } from '../../../hooks/toast';

import { Container } from './styles';

import api from '../../../services/api';

interface UserProps {
  id: string;
  name: string;
  email: string;
  role: RoleProps;
}

interface RoleProps {
  id: string;
  value: string;
  label: string;
}

interface PageDataProps {
  info: {
    title: string;
    description: string;
  };
  add: {
    title: string;
    description: string;
  };
  edit: {
    title: string;
    description: string;
  };
}

interface SubmitFormData {
  name: string;
  company_id: number;
  system: number;
  role_id: number;
}

const User: React.FC = () => {
  const pageData: PageDataProps = {
    info: {
      title: 'Visualizando Usuário',
      description:
        'Abaixo está disponível as informações do usuário selecionado.',
    },
    add: {
      title: 'Criando Usuário',
      description: 'Insira os dados abaixo para criar novo usuário.',
    },
    edit: {
      title: 'Editando Usuário',
      description:
        'Abaixo está disponível as informações do usuário selecionada.',
    },
  };

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const params = useParams<{ type: string; id: string }>();
  const type: keyof PageDataProps = params.type as keyof PageDataProps;
  const { id } = params;

  const breadcrumbs = [
    { title: 'Usuários', to: '' },
    { title: `${pageData[type].title}`, to: '' },
  ];

  const [user, setUser] = useState<UserProps>();
  const [roles, setRoles] = useState<RoleProps[]>([]);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  // loading data
  useEffect(() => {
    if (type === 'add') {
      return;
    }

    api.get(`users/${id}`).then(response => {
      setUser({
        ...response.data,
        // role: {
        //   id: response.data.roles[0]?.id,
        //   value: response.data.roles[0]?.id.toString(),
        //   label: response.data.roles[0]?.name,
        // },
      });
    });
  }, [id, type]);

  // loading data select roles
  useEffect(() => {
    api.get(`/select/roles`).then(response => {
      const result: RoleProps[] = response.data.map((role: any) => {
        return {
          id: role.id.toString(),
          value: role.id.toString(),
          label: role.name,
        };
      });
      setRoles(result);
    });
  }, []);

  // submit add/edit
  const handleSubmit = useCallback(
    async (data: SubmitFormData) => {
      try {
        setLoadingSubmit(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          email: Yup.string().required('E-mail é obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        if (type === 'add') {
          await api.post('users', data);
        } else if (type === 'edit') {
          await api.put(`users/${id}`, data);
        }

        history.push('/users');

        setLoadingSubmit(false);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `${
            type === 'add' ? 'Cadastro' : 'Alteração'
          } realizada com sucesso`,
        });
      } catch (err) {
        setLoadingSubmit(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getvalidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro durante o processo',
          description: 'Ocorreu um erro ao realizar a operação.',
        });
      }
    },
    [type, addToast, history, id],
  );

  return (
    <Container>
      <PageTitle
        icon={FiFolder}
        title={pageData[type].title}
        description={pageData[type].description}
        breadcrumbs={breadcrumbs}
      />
      <ActionArea>
        <div>
          {(type === 'edit' || type === 'add') && (
            <Button type="submit" form="form" loading={loadingSubmit}>
              Salvar
            </Button>
          )}
        </div>
        <div>
          <Button outline>
            <Link to="/users">Voltar</Link>
          </Button>
        </div>
      </ActionArea>
      <Card>
        <Form
          id="form"
          onSubmit={handleSubmit}
          initialData={user}
          ref={formRef}
        >
          <label htmlFor="name">Nome</label>
          <Input
            id="name"
            type="text"
            name="name"
            title="Nome"
            placeholder="Nome do usuário"
            disabled={type === 'info'}
          />

          <label htmlFor="email">E-mail</label>
          <Input
            id="email"
            type="text"
            name="email"
            title="Email"
            placeholder="E-mail do usuário"
            disabled={type === 'info'}
          />

          <label htmlFor="role">Roles</label>
          {type !== 'info' && (user?.role || type === 'add') && (
            <Select name="role_id" options={roles} defaultValue={user?.role} />
          )}
          {type === 'info' && (
            <Input type="text" name="role.label" title="role" disabled />
          )}

          {type === 'add' && (
            <>
              <label htmlFor="password">Senha</label>
              <Input
                id="password"
                type="password"
                name="password"
                title="Senha"
                placeholder="Senha"
              />
              <label htmlFor="passwordConfirm">Confirma senha</label>
              <Input
                id="passwordConfirm"
                type="password"
                name="passwordConfirm"
                title="Confirma senha"
                placeholder="Confirma senha"
              />
            </>
          )}
        </Form>
      </Card>
    </Container>
  );
};

export default User;

import React, { useEffect, useState } from 'react';

import { FiFolder } from 'react-icons/fi';

import PageTitle from '../../components/PageTitle';
import Card from '../../components/Card';
import Button from '../../components/Button';

import { useAuth } from '../../hooks/auth';

import { Container } from './styles';

import api from '../../services/api';
import ControllerPage from '../../components/ControllerPage';

interface Newsletter {
  id: string;
  email: string;
}

interface UserPermissionProps {
  id: string;
  name: string;
}

const Newsletter: React.FC = () => {
  const breadcrumbs = [{ title: 'Newsletter', to: '' }];

  const [newsletters, setNewsletters] = useState<Newsletter[]>([]);

  const { user }: any = useAuth();

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  // loading data list
  useEffect(() => {
    api.get(`newsletter?page=${page}`).then(response => {
      setTotalPage(response.data.totalPage);
      setNewsletters(response.data.data);
    });
  }, [page, user]);

  // // permissão
  // const [hasPermission, setHasPermission] = useState<UserPermissionProps[]>([]);

  // useEffect(() => {
  //   if (user) {
  //     // const userParsed = JSON.parse(user);

  //     // TODO: get the permission in the backend
  //     // userParsed?.roles[0]?.permissions;
  //     const userPermissions: UserPermissionProps[] = [
  //       { id: '1', name: 'newsletter-list' },
  //       { id: '2', name: 'newsletter-create' },
  //       { id: '3', name: 'newsletter-edit' },
  //       { id: '4', name: 'newsletter-delete' },
  //     ];

  //     const permissions = [
  //       'newsletter-list',
  //       'newsletter-create',
  //       'newsletter-edit',
  //       'newsletter-delete',
  //     ];

  //     if (permissions) {
  //       const result: UserPermissionProps[] = userPermissions.filter((x: any) =>
  //         permissions?.includes(x.name),
  //       );
  //       setHasPermission(result);
  //     }
  //   }
  // }, [user]);
  //------------------

  return (
    <Container>
      <PageTitle
        icon={FiFolder}
        title="Lista de Newsletter"
        description="Abaixo está os e-mais que querem receber Newsletter"
        breadcrumbs={breadcrumbs}
      />
      <Card title="Lista de Newsletter">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>E-mail</th>
            </tr>
          </thead>
          <tbody>
            {newsletters.map((u, index) => (
              <tr key={u.id}>
                <td>{index + 1 + (page - 1) * 20}</td>
                <td>{u?.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <ControllerPage>
          {page !== 1 ? (
            <Button onClick={() => setPage(page - 1)}>Página anterior</Button>
          ) : (
            <div />
          )}

          {totalPage > page && (
            <Button onClick={() => setPage(page + 1)}>Próxima página</Button>
          )}
        </ControllerPage>
      </Card>
    </Container>
  );
};

export default Newsletter;

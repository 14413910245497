import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiFolder } from 'react-icons/fi';
import * as Yup from 'yup';

import PageTitle from '../../../components/PageTitle';
import Card from '../../../components/Card';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import ActionArea from '../../../components/ActionArea';
import ImageInput from '../../../components/ImageInput';

import getvalidationErrors from '../../../utils/getvalidationsErrors';

import { useToast } from '../../../hooks/toast';

import { Container } from './styles';

import { ImageContainer } from '../../../components/ImageInput/styles';

import api from '../../../services/api';
import { ContentImage } from '../../Projects/Project/styles';

interface BannerProps {
  id: number;
  order: number;
  url: string;
}

interface PageDataProps {
  info: {
    title: string;
    description: string;
  };
  add: {
    title: string;
    description: string;
  };
  edit: {
    title: string;
    description: string;
  };
}

interface SubmitFormData {
  file: string | Blob;
  order: string;
  title: string;
}

const Banner: React.FC = () => {
  const pageData: PageDataProps = {
    info: {
      title: 'Visualizando banner',
      description:
        'Abaixo está disponível as informações do banner selecionado.',
    },
    add: {
      title: 'Criando banner',
      description: 'Insira os dados abaixo para criar um novo banner.',
    },
    edit: {
      title: 'Editando banner',
      description:
        'Abaixo está disponível as informações do banner selecionado.',
    },
  };

  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();

  const params = useParams<{ type: string; id: string }>();
  const type: keyof PageDataProps = params.type as keyof PageDataProps;
  const { id } = params;

  const breadcrumbs = [
    { title: 'Banners', to: '/banners' },
    { title: `${pageData[type].title}`, to: '' },
  ];

  const [banner, setBanners] = useState<BannerProps>();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  // loading dados
  useEffect(() => {
    if (type === 'add') {
      return;
    }

    api.get(`banners/home/${id}`).then(response => {
      setBanners(response.data);
    });
  }, [id, type]);

  // loading Submit add/edit
  const handleSubmit = useCallback(
    async (data: SubmitFormData) => {
      try {
        setLoadingSubmit(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          file: Yup.string().required('Banner é obrigatório'),
          title: Yup.string().required('Titulo é obrigatória'),
          order: Yup.string().required('Ordem é obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const formData = new FormData();
        formData.append('file', data.file);
        formData.append('order', data.order);
        formData.append('title', data.title);

        if (type === 'add') {
          await api.post('banners/home', formData);
        } else if (type === 'edit') {
          await api.put(`banners/home/${id}`, formData);
        }

        history.push('/banners');

        setLoadingSubmit(false);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `${
            type === 'add' ? 'Cadastro' : 'Alteração'
          } realizada com sucesso`,
        });
      } catch (err) {
        setLoadingSubmit(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getvalidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro durante o processo',
          description: 'Ocorreu um erro ao realizar a operação.',
        });
      }
    },
    [type, addToast, history, id],
  );

  return (
    <Container>
      <PageTitle
        icon={FiFolder}
        title={pageData[type].title}
        description={pageData[type].description}
        breadcrumbs={breadcrumbs}
      />
      <ActionArea>
        <div>
          {(type === 'edit' || type === 'add') && (
            <Button type="submit" form="form" loading={loadingSubmit}>
              Salvar
            </Button>
          )}
        </div>
        <div>
          <Button outline>
            <Link to="/banners">Voltar</Link>
          </Button>
        </div>
      </ActionArea>
      <Card>
        <Form
          id="form"
          onSubmit={handleSubmit}
          initialData={banner}
          ref={formRef}
        >
          <ContentImage>
            {type !== 'add' && banner?.url && (
              <div>
                <label htmlFor="file">
                  {`Imagem do banner${type === 'edit' ? 'Atual' : ''}`}
                </label>
                <ImageContainer>
                  <img src={banner?.url} alt="Imagem" />
                </ImageContainer>
              </div>
            )}
            {!(type === 'info') && (
              <div>
                <label htmlFor="file">
                  {`${type === 'edit' ? 'Nova' : ''} Imagem do banner`}
                </label>
                <ImageInput
                  type="file"
                  name="file"
                  title="Imagem"
                  placeholder="Imagem"
                />
              </div>
            )}
          </ContentImage>

          <label htmlFor="title">Titulo</label>
          <Input
            id="title"
            type="text"
            name="title"
            title="Titulo"
            placeholder="Titulo do banner"
            disabled={type === 'info'}
          />

          <label htmlFor="order">Ordem</label>
          <Input
            id="order"
            type="number"
            name="order"
            title="Ordem"
            placeholder="Ordem de aparição"
            disabled={type === 'info'}
          />
        </Form>
      </Card>
    </Container>
  );
};

export default Banner;

import styled from 'styled-components';

export const Container = styled.div`
  hr {
    margin-top: 30px;
    opacity: 0.2;
  }
`;

export const CardTitle = styled.h3`
  text-transform: uppercase;
  color: ${props => props.theme.colors.cardTitle};
  font-weight: 700;
  font-size: 0.88rem;

  margin: 1.3rem 0;
`;

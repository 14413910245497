import styled from 'styled-components';

export const Container = styled.div`
  background: ${props => props.theme.colors.sideMenu};

  position: fixed;
  height: 100vh;

  box-shadow: 7px 0 60px rgba(0, 0, 0, 0.05);

  width: 300px;
  display: flex;
  z-index: 11;
  overflow: hidden;
  min-width: 300px;
  flex: 0 0 300px;
  margin-top: -60px;
  padding-top: 60px;
  transition: all 0.2s;
`;

export const Content = styled.div`
  z-index: 15;
  width: 100%;
  height: 100%;
  position: relative;
  /* overflow: hidden !important; */
  overflow-anchor: none;
  touch-action: auto;
  /* overflow: scroll; */
  overflow: auto;
  padding: 2px 1.5rem 1.5rem;

  h5 {
    text-transform: uppercase;
    font-size: 0.8rem;
    margin: 0.75rem 0;
    font-weight: 700;
    color: ${props => props.theme.colors.primary};
    white-space: nowrap;
    position: relative;
    line-height: 1.2;
  }
`;

export const MenuContainer = styled.div`
  position: relative;

  ul {
    list-style: none;
    position: relative;
  }
`;

import React from 'react';
import { Link } from 'react-router-dom';
import { IconType } from 'react-icons';
import { FiHome } from 'react-icons/fi';

import {
  Container,
  Wrapper,
  TitleIcon,
  TitleText,
  Breadcrumbs,
} from './styles';

interface BreadcrumbProps {
  title: string;
  to: string;
}

interface Props {
  icon?: IconType;
  title?: string;
  description?: string;
  breadcrumbs?: BreadcrumbProps[];
}

const PageTitle: React.FC<Props> = ({
  icon: Icon,
  title,
  description,
  breadcrumbs,
}) => {
  return (
    <Container>
      <Wrapper>
        <div>
          {Icon && (
            <TitleIcon>
              <i>
                <Icon />
              </i>
            </TitleIcon>
          )}
          <TitleText>
            <h1>{title}</h1>
            <p>{description}</p>
          </TitleText>
        </div>
        <div>
          <Breadcrumbs>
            <nav>
              <ol>
                <li>
                  <Link to="/">
                    <FiHome />
                  </Link>
                </li>
                {breadcrumbs?.map(bread => (
                  <li key={bread.title}>
                    {bread.to !== '' ? (
                      <Link to={bread.to}>{bread.title}</Link>
                    ) : (
                      <>{bread.title}</>
                    )}
                  </li>
                ))}
              </ol>
            </nav>
          </Breadcrumbs>
        </div>
      </Wrapper>
    </Container>
  );
};

export default PageTitle;

import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import Users from '../pages/Users';
import User from '../pages/Users/User';
import Permissions from '../pages/Users/Permissions';
import Permission from '../pages/Users/Permissions/Permission';
import Roles from '../pages/Users/Roles';
import Role from '../pages/Users/Roles/Role';
import Testimonies from '../pages/Testimonies';
import Testimony from '../pages/Testimonies/Testimony';
import Projects from '../pages/Projects';
import Project from '../pages/Projects/Project';
import Banners from '../pages/Banners';
import Banner from '../pages/Banners/Banner';
import Blog from '../pages/Blog';
import Post from '../pages/Blog/Post';
import Newsletter from '../pages/Newsletter';
import SignIn from '../pages/SignIn';
import Dashboard from '../pages/Dashboard';

const Routes: React.FC = () => (
  <>
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/dashboard" component={Dashboard} isPrivate />

      <Route path="/users" exact component={Users} isPrivate />
      <Route path="/users/:type/:id?" component={User} isPrivate />
      <Route path="/permissions" exact component={Permissions} isPrivate />
      <Route path="/permissions/:id?" component={Permission} isPrivate />
      <Route path="/roles" exact component={Roles} isPrivate />
      <Route path="/roles/:id?" component={Role} isPrivate />

      <Route path="/testimonies" exact component={Testimonies} isPrivate />
      <Route path="/testimonies/:type/:id?" component={Testimony} isPrivate />

      <Route path="/projects" exact component={Projects} isPrivate />
      <Route path="/projects/:type/:id?" component={Project} isPrivate />

      <Route path="/blog" exact component={Blog} isPrivate />
      <Route path="/blog/:type/:id?" component={Post} isPrivate />

      <Route path="/banners" exact component={Banners} isPrivate />
      <Route path="/banners/:type/:id?" component={Banner} isPrivate />

      <Route path="/newsletter" exact component={Newsletter} isPrivate />
    </Switch>
  </>
);

export default Routes;

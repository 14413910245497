import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiFolder } from 'react-icons/fi';
import * as Yup from 'yup';

import PageTitle from '../../../../components/PageTitle';
import Card from '../../../../components/Card';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import ActionArea from '../../../../components/ActionArea';
import Checkbox from '../../../../components/Checkbox';
import Select from '../../../../components/Select';

import getvalidationErrors from '../../../../utils/getvalidationsErrors';

import { useToast } from '../../../../hooks/toast';
import { useAuth } from '../../../../hooks/auth';

import { Container } from './styles';

import api from '../../../../services/api';

interface RoleProps {
  id: number;
  name: string;
  company: CompanyProps;
}

interface CompanyProps {
  id: string;
  value: string;
  label: string;
}

interface PageDataProps {
  info: {
    title: string;
    description: string;
  };
  add: {
    title: string;
    description: string;
  };
  edit: {
    title: string;
    description: string;
  };
}

interface SubmitFormData {
  name: string;
  permissions: string[];
  company_id?: number;
}

interface CheckboxOption {
  id: string;
  value: string;
  label: string;
}

interface PermissionProps {
  id: string;
  value: string;
  label: string;
}

const Role: React.FC = () => {
  const pageData: PageDataProps = {
    info: {
      title: 'Visualizando Função',
      description:
        'Abaixo está disponível as informações da função selecionada.',
    },
    add: {
      title: 'Criando Função',
      description: 'Insira os dados abaixo para criar nova função.',
    },
    edit: {
      title: 'Editando Função',
      description:
        'Abaixo está disponível as informações da função selecionada.',
    },
  };

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const [permissions, setPermissions] = useState<PermissionProps[]>([]);

  const params = useParams<{ type: string; id: string }>();
  const type: keyof PageDataProps = params.type as keyof PageDataProps;
  const { id } = params;

  const breadcrumbs = [
    { title: 'Função', to: '' },
    { title: `${pageData[type].title}`, to: '' },
  ];

  const { user }: any = useAuth();

  const [role, setRole] = useState<RoleProps>();
  const [companies, setCompanies] = useState<CompanyProps[]>([]);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  // loading data
  useEffect(() => {
    if (type === 'add') {
      return;
    }

    api.get(`roles/${id}`).then(response => {
      const permissionList = response.data.permissions.map(
        (permission: { id: number }) => permission.id.toString(),
      );

      setRole({
        ...response.data,
        permissions: permissionList,
        company: {
          id: response.data.company?.id,
          value: response.data.company?.id.toString(),
          label: response.data.company?.name,
        },
      });
    });
  }, [id, type]);

  // loading select company
  useEffect(() => {
    api.get('select/companies').then(response => {
      const result: CompanyProps[] = response.data.map((company: any) => {
        return {
          id: company.id,
          value: company.id.toString(),
          label: company.name,
        };
      });
      setCompanies(result);
    });
  }, []);

  // select presmison
  useEffect(() => {
    api.get('/select/permissions').then(response => {
      const result: PermissionProps[] = response.data.map(
        (permission: { id: number; name: string }): PermissionProps => {
          return {
            id: permission.id.toString(),
            value: permission.id.toString(),
            label: permission.name,
          };
        },
      );

      setPermissions(result);
    });
  }, []);

  // submit add/edit
  const handleSubmit = useCallback(
    async (data: SubmitFormData) => {
      try {
        setLoadingSubmit(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O nome da função é obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        if (type === 'add') {
          await api.post('roles', {
            ...data,
            company_id: data.company_id || user?.userapp?.company_id,
          });
        } else if (type === 'edit') {
          await api.put(`roles/${id}`, {
            ...data,
            company_id: data.company_id || user?.userapp?.company_id,
          });
        }

        history.push('/roles');

        setLoadingSubmit(false);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `${
            type === 'add' ? 'Cadastro' : 'Alteração'
          } realizada com sucesso`,
        });
      } catch (err) {
        setLoadingSubmit(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getvalidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro durante o processo',
          description: 'Ocorreu um erro ao realizar a operação.',
        });
      }
    },
    [type, addToast, history, id, user],
  );

  return (
    <Container>
      <PageTitle
        icon={FiFolder}
        title={pageData[type].title}
        description={pageData[type].description}
        breadcrumbs={breadcrumbs}
      />
      <ActionArea>
        <div>
          {(type === 'edit' || type === 'add') && (
            <Button type="submit" form="form" loading={loadingSubmit}>
              Salvar
            </Button>
          )}
        </div>
        <div>
          <Button outline>
            <Link to="/roles">Voltar</Link>
          </Button>
        </div>
      </ActionArea>
      <Card>
        <Form
          id="form"
          onSubmit={handleSubmit}
          initialData={role}
          ref={formRef}
        >
          <label htmlFor="name">Nome</label>
          <Input
            id="name"
            type="text"
            name="name"
            title="Nome"
            placeholder="Nome da função"
            disabled={type === 'info'}
          />

          {!user?.userapp?.company_id && type !== 'info' && (
            <>
              <label htmlFor="company">Empresa</label>
              {(role?.company || type === 'add') && (
                <Select
                  id="company"
                  name="company_id"
                  options={companies}
                  defaultValue={role?.company}
                />
              )}
            </>
          )}

          {type === 'info' && (
            <>
              <label htmlFor="company">Empresa</label>
              <Input
                type="text"
                name="company.label"
                title="company"
                disabled
              />
            </>
          )}

          <label htmlFor="permissions">Permissões</label>
          <Checkbox id="permissions" name="permissions" options={permissions} />
        </Form>
      </Card>
    </Container>
  );
};

export default Role;

import signInbackground from '../../assets/login-bkg.jpg';
import logo from '../../assets/logo.png';

export default {
  title: 'light',

  colors: {
    primary: '#39742F',
    primary_light: '#D0D9CE',
    secondary: '#3A4071',
    tertiary: '#5A5A5A',
    quaternary: '#D7E0E6',

    contrastPrimary: '#fff',

    backgorund: '#efefef',
    text: '#333',

    inputBackground: '#fff',
    inputDisabled: '#ececec',
    inputColor: '#232129',
    inputPlaceholder: '#666360',
    inputBorderColor: '#f8f8f8',

    header: '#fafbfc',

    pageTitleBackground: '#f8f8f8',
    pageTitleIcon: '#d4d3d3',

    sideMenu: '#fff',

    hover: '#e0f3ff',

    borderColor: '#e9ecef',

    cardTitle: 'rgba(18, 21, 78, 0.7)',
  },

  font: `16px 'Roboto', serif`,

  loginBackground: signInbackground,
  loginLogo: logo,

  headerLogo: logo,
};

import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  // isFocused: boolean;
  // isFilled: boolean;
  isErrored: boolean;
  disabled?: boolean;
}

export const Container = styled.div<ContainerProps>`
  color: ${props => props.theme.colors.primary};
  background: ${props => props.theme.colors.inputBackground};
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.disabled &&
    css`
      background-color: ${props.theme.colors.inputDisabled};
      border-color: ${props.theme.colors.inputDisabled};
    `}

  >label{
    margin-top: -15px;
    font-size: 16px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.contrastPrimary};
    padding: 10px 16px;
    border-radius: 6px;
    /* border: 2px solid ${props => props.theme.colors.inputBorderColor}; */
    cursor: pointer;
  }

  input {
    display: none;
    /* background: transparent;
    flex: 1;
    border: 0;
    color: ${props => props.theme.colors.inputColor};
    font-size: 16px;

    ::-webkit-file-upload-button {
      font-size: 16px;
      background: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.contrastPrimary};
      padding: 16px;
      border-radius: 10px;
      border: 2px solid ${props => props.theme.colors.inputBorderColor};
    } */
/*
    &::placeholder {
      color: ${props => props.theme.colors.inputPlaceholder};
    } */
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const ImageContainer = styled.div`
  width: 150px;
  height: 150px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin-bottom: 10px;
  overflow: hidden;
  img {
    width: 100%;
  }
`;

import React, { createContext, useCallback, useContext, useState } from 'react';

import Modal from '../components/Modal';

export interface ModalProps {
  title: string;
  text: string;
  confirm?: boolean;
  onConfirm(): void;
  onCancel(): void;
}

interface ModalContextData {
  addModal(data: ModalProps): void;
  removeModal(): void;
}

const ModalContext = createContext<ModalContextData>({} as ModalContextData);

export const ModalProvider: React.FC = ({ children }) => {
  const [modal, setModal] = useState<ModalProps | null>();

  const addModal = useCallback((data: ModalProps) => {
    setModal(data);
  }, []);

  const removeModal = useCallback(() => {
    setModal(null);
  }, []);

  return (
    <ModalContext.Provider value={{ addModal, removeModal }}>
      {children}
      {modal && <Modal data={modal} />}
    </ModalContext.Provider>
  );
};

export function useModal(): ModalContextData {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}

import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

import light from './styles/theme/light';
// import dark from './styles/theme/dark';

import GlobalStyles from './styles/global';

import AppProvider from './hooks';
// import usePersistState from './hooks/persistState';

import Routes from './routes';

const App: React.FC = () => {
  // const [theme, setTheme] = usePersistState<DefaultTheme>(
  //   '@prosun-panel:theme',
  //   light,
  // );

  // const toggleTheme = () => {
  //   setTheme(theme.title === 'light' ? dark : light);
  // };

  return (
    <BrowserRouter>
      <ThemeProvider theme={light}>
        <AppProvider>
          <Routes />
        </AppProvider>

        <GlobalStyles />
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;

import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SideMenu from '../../components/SideMenu';

import { Container, Content } from './styles';

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <Header />
      <Container>
        <SideMenu />
        <Content>
          {children}
          <Footer />
        </Content>
      </Container>
    </>
  );
};

export default Layout;

import React from 'react';
import { FiBriefcase, FiImage, FiMail } from 'react-icons/fi';

import MenuItem, { MenuItemProps } from './MenuItem';

import { Container, Content, MenuContainer } from './styles';

const SideMenu: React.FC = () => {
  const menuItems: MenuItemProps[] = [
    // {
    //   id: 1,
    //   title: 'Menu',
    //   items: [
    //     {
    //       id: 1,
    //       title: 'Dashboard',
    //       icon: FiHome,
    //       items: [],
    //     },
    //   ],
    // },
    {
      id: 2,
      title: 'Conteúdos do Site',
      items: [
        {
          id: 1,
          title: 'Banners',
          icon: FiImage,
          url: '/banners',
          items: [],
          // permissions: [
          //   'banners-list',
          //   'banners-create',
          //   'banners-edit',
          //   'banners-delete',
          // ],
        },
        {
          id: 2,
          title: 'Depoimentos',
          icon: FiBriefcase,
          url: '/testimonies',
          items: [],
          // permissions: [
          //   'testimony-list',
          //   'testimony-create',
          //   'testimony-edit',
          //   'testimony-delete',
          // ],
        },
        {
          id: 3,
          title: 'Projetos',
          icon: FiMail,
          url: '/projects',
          items: [],
          // permissions: [
          //   'project-list',
          //   'project-create',
          //   'project-edit',
          //   'project-delete',
          // ],
        },
        {
          id: 4,
          title: 'Blog',
          icon: FiMail,
          url: '/blog',
          items: [],
          // permissions: [
          //   'blog-list',
          //   'blog-create',
          //   'blog-edit',
          //   'blog-delete',
          // ],
        },
        {
          id: 5,
          title: 'Newsletter',
          icon: FiMail,
          url: '/newsletter',
          items: [],
          // permissions: [
          //   'newsletter-list',
          //   'newsletter-create',
          //   'newsletter-edit',
          //   'newsletter-delete',
          // ],
        },
      ],
    },
    // {
    //   id: 3,
    //   title: 'Controle de usuários',
    //   // permissions: ['user-list', 'user-create', 'user-edit', 'user-delete'],
    //   items: [
    //     {
    //       id: 1,
    //       title: 'Usuários',
    //       icon: FiUsers,
    //       url: '/users',
    //       items: [],
    //       // permissions: ['user-list', 'user-create', 'user-edit', 'user-delete'],
    //     },
    //     {
    //       id: 2,
    //       title: 'Configurações',
    //       icon: FiSettings,
    //       // permissions: [
    //       //   'role-list',
    //       //   'role-create',
    //       //   'role-edit',
    //       //   'role-delete',
    //       //   'permission-list',
    //       //   'permission-create',
    //       //   'permission-edit',
    //       //   'permission-delete',
    //       // ],
    //       items: [
    //         {
    //           id: 1,
    //           title: 'Funções',
    //           url: '/roles',
    //           items: [],
    //           // permissions: [
    //           //   'role-list',
    //           //   'role-create',
    //           //   'role-edit',
    //           //   'role-delete',
    //           // ],
    //         },
    //         {
    //           id: 2,
    //           title: 'Permissões',
    //           url: '/permissions',
    //           items: [],
    //           // permissions: [
    //           //   'permission-list',
    //           //   'permission-create',
    //           //   'permission-edit',
    //           //   'permission-delete',
    //           // ],
    //         },
    //       ],
    //     },
    //   ],
    // },
  ];

  return (
    <Container>
      <Content>
        <div>
          {menuItems.map(item => (
            <div key={item.title}>
              <h5>{item.title}</h5>
              <MenuContainer>
                {item.items.map(subItem => (
                  <ul key={subItem.id}>
                    <MenuItem data={subItem} submenu />
                  </ul>
                ))}
              </MenuContainer>
            </div>
          ))}
        </div>
      </Content>
    </Container>
  );
};

export default SideMenu;

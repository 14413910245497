import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { IconType } from 'react-icons';

import { Container, Button, Icon, List } from './styles';

export interface MenuItemProps {
  id: number;
  title: string;
  items: MenuItemProps[];
  permissions?: string[];
  icon?: IconType;
  url?: string;
}

interface Props {
  data: MenuItemProps;
  expanded?: boolean;
  submenu?: boolean;
  visible?: boolean;
}

interface UserPermissionProps {
  id: number;
  name: string;
}

const MenuItem: React.FC<Props> = ({ data, submenu, expanded }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Container>
      <Button
        onClick={() => {
          if (!data.url) {
            setOpen(data.items.length > 0 && !isOpen);
          }
        }}
        open={expanded}
        submenu={submenu}
        // visible={hasPermission ? !(hasPermission?.length > 0) : false}
      >
        <Link to={data.url ? data.url : '#'}>
          <Icon>{data.icon && <data.icon size={22} />}</Icon>
          {data.title}
          {data.items.length > 0 && (
            <Icon>{isOpen ? <FiChevronUp /> : <FiChevronDown />}</Icon>
          )}
        </Link>
      </Button>
      <List open={isOpen} verticalLine={data.items.length > 0}>
        {data.items.map(subItem => (
          <MenuItem key={subItem.id} data={subItem} expanded={isOpen} />
        ))}
      </List>
    </Container>
  );
};

export default MenuItem;

import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  disabled?: boolean;
}

export const Container = styled.div<ContainerProps>`
  color: ${props => props.theme.colors.primary};
  background: ${props => props.theme.colors.inputBackground};
  border-radius: 10px;
  border: 2px solid ${props => props.theme.colors.inputBorderColor};
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      color: ${props.theme.colors.primary};
      border-color: ${props.theme.colors.primary};
    `}

  ${props =>
    props.isFilled &&
    css`
      color: ${props.theme.colors.inputPlaceholder};
    `}

  ${props =>
    props.disabled &&
    css`
      background-color: ${props.theme.colors.inputDisabled};
      border-color: ${props.theme.colors.inputDisabled};
    `}

    textarea {
    background: transparent;
    flex: 1;
    border: 0;
    color: ${props => props.theme.colors.inputColor};
    font-size: 16px;
    height: 100px;
    padding-right: 10px;

    &::placeholder {
      color: ${props => props.theme.colors.inputPlaceholder};
    }

    &::-webkit-scrollbar {
      width: 12px;
      background-color: ${props => props.theme.colors.contrastPrimary};
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: ${props => props.theme.colors.primary};
    }

    &::placeholder {
      color: ${props => props.theme.colors.inputPlaceholder};
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  align-items: center;
  /* padding: 0 20px; */
  button {
    width: 150px;
    height: 30px;
  }
  p {
  }
`;

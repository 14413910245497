import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 30px !important;

  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
    0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
    0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
    0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
  border-width: 0;
  transition: all 0.2s;

  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: initial;
  border: 1px solid rgba(32, 39, 140, 0.125);
  border-radius: 0.25rem;
`;

export const CardBody = styled.div`
  flex: 1 1 auto;
  padding: 1.25rem;

  table {
    margin-bottom: 0;

    width: 100%;
    color: #212529;

    border-collapse: collapse;

    tbody {
      tr {
        &:hover {
          background-color: ${props => props.theme.colors.hover};
        }
      }
    }

    th {
      vertical-align: middle;

      border-bottom: 2px solid ${props => props.theme.colors.borderColor};

      padding: 0.55rem;
      border-top: 1px solid ${props => props.theme.colors.borderColor};

      text-align: inherit;
    }

    td {
      padding: 0.55rem;
      border-top: 1px solid ${props => props.theme.colors.borderColor};
    }
  }
`;

export const CardTitle = styled.h3`
  text-transform: uppercase;
  color: ${props => props.theme.colors.cardTitle};
  font-weight: 700;
  font-size: 0.88rem;

  margin-bottom: 0.75rem;
`;

import styled, { css } from 'styled-components';

interface MenuItemProps {
  open?: boolean;
  submenu?: boolean;
  verticalLine?: boolean;
  visible?: boolean;
}

export const Container = styled.li`
  position: relative;
`;

export const Button = styled.div<MenuItemProps>`
  a {
    transition: transform 0.2s, height 0.3s, color 0.3s, background-color 0.3s;
    /* display: block; */
    display: ${props => (props.visible ? 'none' : 'block')};
    line-height: 2.5em;
    height: 2.5rem;
    padding: 0 1.5rem 0 45px;
    position: relative;
    border-radius: 0.25rem;
    color: #343a40;
    white-space: nowrap;

    outline: none !important;

    text-decoration: none;
    background-color: initial;

    ${props =>
      !props.submenu &&
      !props.open &&
      css`
        height: 0;
        overflow: hidden;
        padding-left: 1em;
      `}

    ${props =>
      !props.submenu &&
      props.open &&
      css`
        height: 2.3em;
        line-height: 2.3em;
        color: #6c757d;
        margin: 0 0 3px;
        padding-left: 1em;
      `}

  &:hover {
      background: #e0f3ff;
    }
  }
`;

export const List = styled.ul<MenuItemProps>`
  transition: padding 0.3s;

  ${props =>
    props.open &&
    css`
      padding: 0.5em 0 0 2rem;
      visibility: visible !important;

      &::before {
        content: '';
        height: 100%;
        opacity: 1;
        width: 3px;
        background: ${props.theme.colors.hover};
        position: absolute;
        left: 20px;
        top: 0;
        border-radius: 15px;
      }
    `}
`;

export const Icon = styled.i`
  text-align: center;
  width: 34px;
  height: 34px;
  position: absolute;
  left: 5px;
  top: 50%;
  margin-top: -17px;
  opacity: 0.3;
  transition: color 0.3s;

  -webkit-font-smoothing: antialiased;

  & + i {
    transition: transform 0.3s;
    left: auto;
    right: 0;
  }
`;

import React from 'react';

import { Container } from './styles';

// interface Props {
//   title?: string;
// }

const ControllerPage: React.FC = ({ children }) => {
  return <Container>{children}</Container>;
};

export default ControllerPage;

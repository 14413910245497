import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiFolder } from 'react-icons/fi';
import * as Yup from 'yup';

import PageTitle from '../../../components/PageTitle';
import Card from '../../../components/Card';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import ActionArea from '../../../components/ActionArea';
import ImageInput from '../../../components/ImageInput';

import getvalidationErrors from '../../../utils/getvalidationsErrors';
// import getStateList from '../../../utils/getStateList';

import { useToast } from '../../../hooks/toast';

import { Container } from './styles';
import { ImageContainer } from '../../../components/ImageInput/styles';

import api from '../../../services/api';

interface UF {
  id: string;
  title: string;
}

interface TestimonyProps {
  id: string;
  name: string;
  locale: string;
  text: string;
  avatar_url: string;
}

interface PageDataProps {
  info: {
    title: string;
    description: string;
  };
  add: {
    title: string;
    description: string;
  };
  edit: {
    title: string;
    description: string;
  };
}

interface SubmitFormData {
  name: string;
  file: string | Blob;
}

const Testimony: React.FC = () => {
  const pageData: PageDataProps = {
    info: {
      title: 'Visualizando Depoimento',
      description:
        'Abaixo está disponível as informações do depoimento selecionado.',
    },
    add: {
      title: 'Criando Depoimento',
      description: 'Insira os dados abaixo para criar nova depoimento.',
    },
    edit: {
      title: 'Editando Depoimento',
      description:
        'Abaixo está disponível as informações do depoimento selecionado.',
    },
  };

  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();

  const params = useParams<{ type: string; id: string }>();
  const type: keyof PageDataProps = params.type as keyof PageDataProps;
  const { id } = params;

  const breadcrumbs = [
    { title: 'Depoimentos', to: '/testimonies' },
    { title: `${pageData[type].title}`, to: '' },
  ];

  const [testimony, setTestimony] = useState<TestimonyProps>();
  // const [uf, setUf] = useState<UF[]>([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  // loading dados
  useEffect(() => {
    if (type === 'add') {
      return;
    }

    api.get(`testimonies/${id}`).then(response => {
      setTestimony(response.data);
    });

    // async function loadUfs() {
    //   setUf(await getStateList());
    // }

    // loadUfs();
  }, [id, type]);

  // loading Submit add/edit
  const handleSubmit = useCallback(
    async (data: SubmitFormData) => {
      try {
        setLoadingSubmit(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          locale: Yup.string().required('Local é obrigatório'),
          text: Yup.string().required('Mensagem é obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        const formData = new FormData();
        formData.append('file', data.file);

        if (type === 'add') {
          const response = await api.post('testimonies', data);

          if (data.file) {
            await api.patch(`testimonies/avatar/${response.data.id}`, formData);
          }
        } else if (type === 'edit') {
          await api.put(`testimonies/${id}`, data);

          if (data.file) {
            await api.patch(`testimonies/avatar/${id}`, formData);
          }
        }

        history.push('/testimonies');

        setLoadingSubmit(false);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `${
            type === 'add' ? 'Cadastro' : 'Alteração'
          } realizada com sucesso`,
        });
      } catch (err) {
        setLoadingSubmit(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getvalidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro durante o processo',
          description: 'Ocorreu um erro ao realizar a operação.',
        });
      }
    },
    [type, addToast, history, id],
  );

  return (
    <Container>
      <PageTitle
        icon={FiFolder}
        title={pageData[type].title}
        description={pageData[type].description}
        breadcrumbs={breadcrumbs}
      />
      <ActionArea>
        <div>
          {(type === 'edit' || type === 'add') && (
            <Button type="submit" form="form" loading={loadingSubmit}>
              Salvar
            </Button>
          )}
        </div>
        <div>
          <Button outline>
            <Link to="/testimonies">Voltar</Link>
          </Button>
        </div>
      </ActionArea>
      <Card>
        <Form
          id="form"
          onSubmit={handleSubmit}
          initialData={testimony}
          ref={formRef}
        >
          <label htmlFor="file">Foto</label>
          {!(type === 'info') ? (
            <ImageInput
              id="file"
              type="file"
              name="file"
              title="Foto"
              placeholder="Foto"
            />
          ) : (
            <>
              <br />
              <ImageContainer>
                <img src={testimony?.avatar_url} alt="Foto" />
              </ImageContainer>
            </>
          )}

          <label htmlFor="name">Nome</label>
          <Input
            id="name"
            type="text"
            name="name"
            title="Nome"
            placeholder="Nome da imóvel"
            disabled={type === 'info'}
          />

          <label htmlFor="locale">Local</label>
          <Input
            id="locale"
            type="text"
            name="locale"
            title="Local"
            placeholder="Local (Nome da cidade - UF)"
            disabled={type === 'info'}
          />

          <label htmlFor="text">Mensagem</label>
          <Input
            id="text"
            type="text"
            name="text"
            title="Mensagem"
            placeholder="Mensagem"
            disabled={type === 'info'}
          />
        </Form>
      </Card>
    </Container>
  );
};

export default Testimony;

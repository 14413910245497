import styled from 'styled-components';
// import { ButtonHTMLAttributes } from 'react';

export const Container = styled.div`
  width: 100%;
  height: 60px;
  background: ${props => props.theme.colors.header};
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
    0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
    0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
    0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);

  position: fixed;

  display: flex;
  align-items: center;
  align-content: center;
  z-index: 10;
  transition: all 0.2s;
`;

export const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 60px;
  width: 300px;
  padding: 10px 1.5rem;

  transition: width 0.2s;

  img {
    height: 100%;
  }
`;

export const Content = styled.div`
  height: 60px;
  flex: 1 1;
  padding: 0 1.5rem;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

export const LogoutButton = styled.button`
  border: 0;
  background: transparent;
  color: ${props => props.theme.colors.primary};
`;

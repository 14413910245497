import React from 'react';
import { Link } from 'react-router-dom';
import { FiEdit, FiDelete, FiInfo } from 'react-icons/fi';
import Loader from 'react-loader-spinner';

import { Container, Action } from './styles';

interface Props {
  id?: string;
  path: string;
  onDelete(): void;
  hasPermission?: UserPermissionProps[];
  loadingDelete?: boolean;
}

interface UserPermissionProps {
  id: string;
  name: string;
}

const ActionButton: React.FC<Props> = ({
  id,
  path,
  onDelete,
  hasPermission,
  loadingDelete,
}) => {
  // const [hasPermission, setHasPermission] = useState<UserPermissionProps[]>([]);

  // const user = localStorage.getItem('@zafety:user');
  // useEffect(() => {
  //   if (user) {
  //     const userPermissions = JSON.parse(user)?.roles[0]?.permissions;

  //     if (permissions) {
  //       const result: UserPermissionProps[] = userPermissions.filter((x: any) =>
  //         permissions?.includes(x.name),
  //       );
  //
  //       setHasPermission(result);
  //     }
  //   }
  // }, [permissions, user]);

  return (
    <Container>
      {hasPermission?.map(
        (x: any) =>
          x.name.indexOf('list') !== -1 && (
            <Action type="info" key={x.name.toString()}>
              <Link to={`${path}/info/${id}`}>
                <FiInfo />
              </Link>
            </Action>
          ),
      )}
      {hasPermission?.map(
        (x: any) =>
          x.name.indexOf('edit') !== -1 && (
            <Action type="edit" key={x.name.toString()}>
              <Link to={`${path}/edit/${id}`}>
                <FiEdit />
              </Link>
            </Action>
          ),
      )}
      {hasPermission?.map(
        (x: any) =>
          x.name.indexOf('delete') !== -1 && (
            <Action
              type="delete"
              key={x.name.toString()}
              loading={loadingDelete}
            >
              <button type="button" onClick={onDelete} disabled={loadingDelete}>
                {loadingDelete ? (
                  <Loader
                    type="ThreeDots"
                    color="#fff"
                    height={15}
                    width={15}
                    timeout={0}
                  />
                ) : (
                  <FiDelete />
                )}
              </button>
            </Action>
          ),
      )}
    </Container>
  );
};

export default ActionButton;

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiFolder } from 'react-icons/fi';
import * as Yup from 'yup';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import PageTitle from '../../../components/PageTitle';
import Card from '../../../components/Card';
import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';
import Button from '../../../components/Button';
import ActionArea from '../../../components/ActionArea';
import Select from '../../../components/Select';
import ImageInput from '../../../components/ImageInput';

import getvalidationErrors from '../../../utils/getvalidationsErrors';

import { useToast } from '../../../hooks/toast';

import { Container, ContainerContent } from './styles';
import { ImageContainer } from '../../../components/ImageInput/styles';

import api from '../../../services/api';
import FormMetadata from '../../../components/FormMetadata';
import { ContentImage } from '../../Projects/Project/styles';

interface MetadataProps {
  seo_url: string;
  title: string;
  description: string;
  keywords: string;
  canonical_url: string;
  og_title: string;
  og_description: string;
  og_image: string;
  twitter_title: string;
  twitter_description: string;
}

interface PostProps {
  id: string;
  title: string;
  summary: string;
  content: number;
  status_id: number;
  thumb_url: string;
  status: {
    name: string;
  };
  metadata: MetadataProps;
}

interface PageDataProps {
  info: {
    title: string;
    description: string;
  };
  add: {
    title: string;
    description: string;
  };
  edit: {
    title: string;
    description: string;
  };
}

interface SelectOptionProps {
  id: string;
  value: string;
  label: string;
}

interface SubmitFormData {
  title: string;
  summary: string;
  content: number;
  status_id: string;
  file: string | Blob;
}

const Post: React.FC = () => {
  const pageData: PageDataProps = {
    info: {
      title: 'Visualizando Post',
      description: 'Abaixo está disponível as informações do post selecionada.',
    },
    add: {
      title: 'Criando Post',
      description: 'Insira os dados abaixo para criar nova post.',
    },
    edit: {
      title: 'Editando Post',
      description: 'Abaixo está disponível as informações do post selecionada.',
    },
  };

  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();

  const params = useParams<{ type: string; id: string }>();
  const type: keyof PageDataProps = params.type as keyof PageDataProps;
  const { id } = params;

  const breadcrumbs = [
    { title: 'Posts', to: '/blog' },
    { title: `${pageData[type].title}`, to: '' },
  ];

  const [post, setPost] = useState<PostProps>();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [statuses, setStatuses] = useState<SelectOptionProps[]>();
  const [content, setContent] = useState('');

  const handleEditoText = useCallback(value => {
    setContent(value);
  }, []);

  // loading dados
  useEffect(() => {
    if (type === 'add') {
      return;
    }

    api.get(`blog/${id}`).then(response => {
      setPost(response.data);
      setContent(response.data.content);
    });
  }, [id, type]);

  useEffect(() => {
    api.get<{ id: string; name: string }[]>(`statuses`).then(response => {
      setStatuses(
        response.data.map(status => {
          return {
            id: status.id,
            value: status.id,
            label: status.name,
          };
        }),
      );
    });
  }, []);

  // loading Submit add/edit
  const handleSubmit = useCallback(
    async (data: SubmitFormData) => {
      try {
        setLoadingSubmit(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('Titulo é obrigatório'),
          summary: Yup.string().required('Resumo é obrigatório'),
          // content: Yup.string().required('Conteúdo é obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        const formData = new FormData();
        formData.append('file', data.file);

        if (type === 'add') {
          const response = await api.post('blog', { ...data, content });

          if (data.file) {
            await api.patch(`blog/thumb/${response.data.id}`, formData);
          }
        } else if (type === 'edit') {
          await api.put(`blog/${id}`, { ...data, content });

          if (data.file) {
            await api.patch(`blog/thumb/${id}`, formData);
          }
        }

        history.push('/blog');

        setLoadingSubmit(false);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `${
            type === 'add' ? 'Cadastro' : 'Alteração'
          } realizada com sucesso`,
        });
      } catch (err) {
        setLoadingSubmit(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getvalidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro durante o processo',
          description: 'Ocorreu um erro ao realizar a operação.',
        });
      }
    },
    [type, addToast, history, id, content],
  );

  return (
    <Container>
      <PageTitle
        icon={FiFolder}
        title={pageData[type].title}
        description={pageData[type].description}
        breadcrumbs={breadcrumbs}
      />
      <ActionArea>
        <div>
          {(type === 'edit' || type === 'add') && (
            <Button type="submit" form="form" loading={loadingSubmit}>
              Salvar
            </Button>
          )}
        </div>
        <div>
          <Button outline>
            <Link to="/blog">Voltar</Link>
          </Button>
        </div>
      </ActionArea>
      <Card title="Dados Basicos">
        <Form
          id="form"
          onSubmit={handleSubmit}
          initialData={post}
          ref={formRef}
        >
          <ContentImage>
            {type !== 'add' && post?.thumb_url && (
              <div>
                <label htmlFor="file">
                  {`Thumb ${type === 'edit' ? 'Atual' : ''}`}
                </label>
                <ImageContainer>
                  <img src={post?.thumb_url} alt="Imagem" />
                </ImageContainer>
              </div>
            )}
            {!(type === 'info') && (
              <div>
                <label htmlFor="file">
                  {`${type === 'edit' ? 'Nova' : ''} Thumb`}
                </label>
                <ImageInput
                  type="file"
                  name="file"
                  title="Imagem"
                  placeholder="Imagem"
                />
              </div>
            )}
          </ContentImage>

          <label htmlFor="title">Titulo</label>
          <Input
            id="title"
            type="text"
            name="title"
            title="Titulo"
            placeholder="Titulo"
            disabled={type === 'info'}
          />

          <label htmlFor="summary">Resumo</label>
          <TextArea
            id="summary"
            // type="text"
            name="summary"
            title="Resumo"
            placeholder="Resumo "
            disabled={type === 'info'}
          />

          <label htmlFor="content">Conteúdo</label>
          {/* <TextArea
            id="content"
            // type="text"
            name="content"
            title="Conteúdo"
            placeholder="Conteúdo"
            disabled={type === 'info'}
          /> */}
          {type !== 'info' ? (
            <ReactQuill
              theme="snow"
              value={content}
              onChange={handleEditoText}
            />
          ) : (
            <ContainerContent dangerouslySetInnerHTML={{ __html: content }} />
          )}

          {/* defaultValue={`${dangerouslySetInnerHTML={{ __html: blog.content }}}` */}

          <label htmlFor="status_id">Status</label>
          {type !== 'info' && (post?.status_id || type === 'add') && (
            <Select
              name="status_id"
              defaultValue={{
                id: post?.status_id,
                value: post?.status_id,
                label: post?.status.name,
              }}
              options={statuses}
            />
          )}
          {type === 'info' && (
            <Input
              id="status_id"
              type="text"
              name="status.name"
              title="Status"
              placeholder="Status"
              disabled={type === 'info'}
            />
          )}

          <Input
            type="text"
            name="metadata_id"
            disabled={type === 'info'}
            hidden
          />
          {/* SEO */}
          <FormMetadata type={type} />
        </Form>
      </Card>
    </Container>
  );
};

export default Post;

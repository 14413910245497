import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 10px;

  label {
    margin-right: 10px;
  }

  input {
    margin-right: 5px;
    border-radius: 0.25rem;

    width: 16px;
    height: 16px;
  }
`;

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiFolder } from 'react-icons/fi';
import * as Yup from 'yup';

import PageTitle from '../../../components/PageTitle';
import Card from '../../../components/Card';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import ActionArea from '../../../components/ActionArea';
import Select from '../../../components/Select';
import ImageInput from '../../../components/ImageInput';

import getvalidationErrors from '../../../utils/getvalidationsErrors';

import { useToast } from '../../../hooks/toast';

import { Container, ContentImage } from './styles';
import { ImageContainer } from '../../../components/ImageInput/styles';

import api from '../../../services/api';
import FormMetadata from '../../../components/FormMetadata';

interface MetadataProps {
  seo_url: string;
  title: string;
  description: string;
  keywords: string;
  canonical_url: string;
  og_title: string;
  og_description: string;
  og_image: string;
  twitter_title: string;
  twitter_description: string;
}

interface ProjectProps {
  id: string;
  name: string;
  locale: string;
  kwp: number;
  panels: number;
  image_url: string;
  status_id: string;
  type_id: string;
  metadata_id: string;
  type: {
    name: string;
  };
  status: {
    name: string;
  };
  metadata: MetadataProps;
}

interface PageDataProps {
  info: {
    title: string;
    description: string;
  };
  add: {
    title: string;
    description: string;
  };
  edit: {
    title: string;
    description: string;
  };
}

interface SelectOptionProps {
  id: string;
  value: string;
  label: string;
}

interface SubmitFormData {
  name: string;
  file: string | Blob;
}

const Project: React.FC = () => {
  const pageData: PageDataProps = {
    info: {
      title: 'Visualizando Projeto',
      description:
        'Abaixo está disponível as informações do projeto selecionada.',
    },
    add: {
      title: 'Criando Projeto',
      description: 'Insira os dados abaixo para criar nova projeto.',
    },
    edit: {
      title: 'Editando Projeto',
      description:
        'Abaixo está disponível as informações do projeto selecionada.',
    },
  };

  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();

  const params = useParams<{ type: string; id: string }>();
  const type: keyof PageDataProps = params.type as keyof PageDataProps;
  const { id } = params;

  const breadcrumbs = [
    { title: 'Projetos', to: '/projects' },
    { title: `${pageData[type].title}`, to: '' },
  ];

  const [project, setProject] = useState<ProjectProps>();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [statuses, setStatuses] = useState<SelectOptionProps[]>();
  const [projectTypes, setProjectTypes] = useState<SelectOptionProps[]>();

  // loading dados
  useEffect(() => {
    if (type === 'add') {
      return;
    }

    api.get(`projects/${id}`).then(response => {
      setProject(response.data);
    });
  }, [id, type]);

  useEffect(() => {
    api.get<{ id: string; name: string }[]>(`statuses`).then(response => {
      setStatuses(
        response.data.map(status => {
          return {
            id: status.id,
            value: status.id,
            label: status.name,
          };
        }),
      );
    });

    api.get<{ id: string; name: string }[]>(`projects/types`).then(response => {
      setProjectTypes(
        response.data.map(projectType => {
          return {
            id: projectType.id,
            value: projectType.id,
            label: projectType.name,
          };
        }),
      );
    });
  }, []);

  // loading Submit add/edit
  const handleSubmit = useCallback(
    async (data: SubmitFormData) => {
      try {
        setLoadingSubmit(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          locale: Yup.string().required('Local é obrigatório'),
          kwp: Yup.string().required('Valor de kwp é obrigatório'),
          type_id: Yup.string().required('Tipo é obrigatório'),
          status_id: Yup.string().required('Status é obrigatório'),
          panels: Yup.string().required('Quantidade de painel é obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        const formData = new FormData();
        formData.append('file', data.file);

        if (type === 'add') {
          const response = await api.post('projects', data);

          if (data.file) {
            await api.patch(`projects/image/${response.data.id}`, formData);
          }
        } else if (type === 'edit') {
          await api.put(`projects/${id}`, data);

          if (data.file) {
            await api.patch(`projects/image/${id}`, formData);
          }
        }

        history.push('/projects');

        setLoadingSubmit(false);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `${
            type === 'add' ? 'Cadastro' : 'Alteração'
          } realizada com sucesso`,
        });
      } catch (err) {
        setLoadingSubmit(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getvalidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro durante o processo',
          description: 'Ocorreu um erro ao realizar a operação.',
        });
      }
    },
    [type, addToast, history, id],
  );

  return (
    <Container>
      <PageTitle
        icon={FiFolder}
        title={pageData[type].title}
        description={pageData[type].description}
        breadcrumbs={breadcrumbs}
      />
      <ActionArea>
        <div>
          {(type === 'edit' || type === 'add') && (
            <Button type="submit" form="form" loading={loadingSubmit}>
              Salvar
            </Button>
          )}
        </div>
        <div>
          <Button outline>
            <Link to="/projects">Voltar</Link>
          </Button>
        </div>
      </ActionArea>
      <Card title="Dados Basicos">
        <Form
          id="form"
          onSubmit={handleSubmit}
          initialData={project}
          ref={formRef}
        >
          <ContentImage>
            {type !== 'add' && project?.image_url && (
              <div>
                <label htmlFor="file">
                  {`Imagem ${type === 'edit' ? 'Atual' : ''}`}
                </label>
                <ImageContainer>
                  <img src={project?.image_url} alt="Imagem" />
                </ImageContainer>
              </div>
            )}
            {!(type === 'info') && (
              <div>
                <label htmlFor="file">
                  {`${type === 'edit' ? 'Nova' : ''} Imagem`}
                </label>
                <ImageInput
                  type="file"
                  name="file"
                  title="Imagem"
                  placeholder="Imagem"
                />
              </div>
            )}
          </ContentImage>
          <label htmlFor="name">Nome</label>
          <Input
            id="name"
            type="text"
            name="name"
            title="Nome"
            placeholder="Nome da imóvel"
            disabled={type === 'info'}
          />

          <label htmlFor="locale">Local</label>
          <Input
            id="locale"
            type="text"
            name="locale"
            title="Local"
            placeholder="Local (Nome da cidade - UF)"
            disabled={type === 'info'}
          />

          <label htmlFor="type_id">Tipo</label>
          {type !== 'info' && (project?.type_id || type === 'add') && (
            <Select
              name="type_id"
              defaultValue={{
                id: project?.type_id,
                value: project?.type_id,
                label: project?.type.name,
              }}
              options={projectTypes}
            />
          )}
          {type === 'info' && (
            <Input
              id="type_id"
              type="text"
              name="type.name"
              title="Tipo"
              placeholder="Tipo"
              disabled={type === 'info'}
            />
          )}

          <label htmlFor="kwp">Kwp</label>
          <Input
            id="kwp"
            type="number"
            name="kwp"
            title="Kwp"
            placeholder="Kwp"
            disabled={type === 'info'}
          />

          <label htmlFor="panels">Paineis</label>
          <Input
            id="panels"
            type="number"
            name="panels"
            title="Paineis"
            placeholder="Número de Paineis"
            disabled={type === 'info'}
          />

          <label htmlFor="status_id">Status</label>
          {type !== 'info' && (project?.status_id || type === 'add') && (
            <Select
              name="status_id"
              defaultValue={{
                id: project?.status_id,
                value: project?.status_id,
                label: project?.status.name,
              }}
              options={statuses}
            />
          )}
          {type === 'info' && (
            <Input
              id="status_id"
              type="text"
              name="status.name"
              title="Status"
              placeholder="Status"
              disabled={type === 'info'}
            />
          )}

          <Input
            type="text"
            name="metadata_id"
            disabled={type === 'info'}
            hidden
          />
          {/* SEO */}
          <FormMetadata type={type} />
        </Form>
      </Card>
    </Container>
  );
};

export default Project;

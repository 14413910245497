import styled from 'styled-components';

interface BadgeProps {
  type?: string;
}

export const Container = styled.div`
  flex: 1 1 auto;
  padding: 1.25rem;
`;

import styled from 'styled-components';

interface BadgeProps {
  type?: string;
}

export const Container = styled.div`
  flex: 1 1 auto;
  padding: 1.25rem;
`;

export const Badge = styled.div<BadgeProps>`
  background: ${props =>
    props.type ? (props.type === 'system' ? '#30b1ff' : '#3ac47a') : '#c5c5c5'};

  display: inherit;
  border-radius: 6px;
  padding: 5px 5px;
  color: #fff;
  font-size: 12px;
`;

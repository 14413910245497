import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 60px;

  z-index: 8;
  position: relative;
  display: flex;
`;

export const Content = styled.div`
  padding-bottom: 60px;
  padding-left: 300px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 1px;
`;

import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  outline?: boolean;
  loading?: boolean;
}

export const Container = styled.button<ContainerProps>`
  background: ${props =>
    props.outline ? 'transparent' : props.theme.colors.primary};
  height: 56px;
  border-radius: 10px;
  border: 0;
  padding: 0 16px;
  color: ${props =>
    props.outline
      ? props.theme.colors.primary
      : props.theme.colors.contrastPrimary};
  width: 100%;
  font-weight: 500;
  margin-top: 16px;
  transition: background-color 0.2s;

  opacity: ${props => (props.loading ? 0.7 : 1)};

  ${props =>
    props.outline &&
    css`
      border: 1px solid ${props.theme.colors.primary};
    `}

  a {
    text-decoration: none;
    color: inherit;
  }

  a:hover,
  &:hover {
    background: ${props => shade(0.2, `${props.theme.colors.primary}`)};
    color: ${props => props.theme.colors.contrastPrimary};
  }
`;

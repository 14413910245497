import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 60px;
  bottom: 0;
  left: 0;
  z-index: 7;
`;

export const Content = styled.div`
  margin-left: 280px;
  box-shadow: 0.3rem -0.46875rem 2.1875rem rgba(8, 10, 37, 0.02),
    0.3rem -0.9375rem 1.40625rem rgba(8, 10, 37, 0.02),
    0.3rem -0.25rem 0.53125rem rgba(8, 10, 37, 0.04),
    0.3rem -0.125rem 0.1875rem rgba(8, 10, 37, 0.02);

  background: #fafbfc;

  display: flex;
  align-items: center;

  padding: 0 1.5rem 0 0.75rem;
  height: 60px;
  transition: all 0.2s;
  align-content: center;
`;

import styled from 'styled-components';

export const Container = styled.div`
  flex: 1 1 auto;
  padding: 1.25rem;

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  div + label {
    margin-top: 20px;
  }

  button {
    height: 35px;
    width: auto;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }
`;

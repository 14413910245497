import styled from 'styled-components';
import { animated } from 'react-spring';
import { shade } from 'polished';

export const Container = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  position: fixed;
`;

export const Content = styled(animated.div)`
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 101;
  background: #fff;
  box-shadow: 0 0.76875rem 2.4875rem rgba(52, 58, 64, 0.3),
    0 1.3375rem 1.70625rem rgba(52, 58, 64, 0.3),
    0 0.55rem 0.53125rem rgba(0, 0, 0, 0.05),
    0 0.225rem 0.4375rem rgba(52, 58, 64, 0.3);
  border-radius: 0.25rem;
  height: auto !important;

  width: 400px;

  > button {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 16px;
    height: 16px;
    border: 0;
    background: transparent;
    color: #adb5bd;
    transition: 0.2s color;

    &:hover {
      color: #353b41;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;

  background: #f8f9fa;

  h5 {
    font-weight: 400;
    margin-top: 0;

    font-size: 1.25rem;

    margin-bottom: 0;
    line-height: 1.5;
  }
`;

export const Body = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;

  background: #f8f9fa;

  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;

  button {
    background-color: initial;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    color: ${props => props.theme.colors.primary};
    width: auto;
    height: auto;
    border-radius: 6px;
    margin: 0;

    &:hover {
      background-color: initial;
      color: ${props => shade(0.2, `${props.theme.colors.primary}`)};
      text-decoration: underline;
    }

    & + button {
      background-color: ${props => props.theme.colors.primary};
      color: #fff;

      &:hover {
        background-color: ${props =>
          shade(0.2, `${props.theme.colors.primary}`)};
        color: #fff;
        text-decoration: none;
      }
    }
  }
`;

import React from 'react';

import Input from '../Input';

import { Container, CardTitle } from './styles';

interface IProps {
  type: string;
}

const FormMetadata: React.FC<IProps> = ({ type }) => {
  return (
    <Container>
      <hr />
      <CardTitle>Dados de SEO</CardTitle>
      <label htmlFor="seo_url">SEO Url</label>
      <Input
        id="seo_url"
        type="text"
        name="metadata.seo_url"
        title="SEO Url"
        placeholder="SEO Url"
        disabled={type === 'info'}
      />

      <label htmlFor="title">Titulo</label>
      <Input
        id="title"
        type="text"
        name="metadata.title"
        title="Titulo"
        placeholder="Titulo"
        disabled={type === 'info'}
      />

      <label htmlFor="description">Descrição</label>
      <Input
        id="description"
        type="text"
        name="metadata.description"
        title="Descrição"
        placeholder="Descrição"
        disabled={type === 'info'}
      />

      <label htmlFor="keywords">Palavras Chaves</label>
      <Input
        id="keywords"
        type="text"
        name="metadata.keywords"
        title="Palavras Chaves"
        placeholder="Palavras Chaves"
        disabled={type === 'info'}
      />

      <label htmlFor="keywords">Palavras Chaves</label>
      <Input
        id="keywords"
        type="text"
        name="metadata.keywords"
        title="Palavras Chaves"
        placeholder="Palavras Chaves"
        disabled={type === 'info'}
      />

      <label htmlFor="canonical_url">Url Conica</label>
      <Input
        id="canonical_url"
        type="text"
        name="metadata.canonical_url"
        title="Url Conica"
        placeholder="Url Conica"
        disabled={type === 'info'}
      />

      <label htmlFor="og_title">Titulo (OpenGraph)</label>
      <Input
        id="og_title"
        type="text"
        name="metadata.og_title"
        title="Titulo (OpenGraph)"
        placeholder="Titulo (OpenGraph)"
        disabled={type === 'info'}
      />

      <label htmlFor="og_description">Descrição (OpenGraph)</label>
      <Input
        id="og_description"
        type="text"
        name="metadata.og_description"
        title="Descrição (OpenGraph)"
        placeholder="Descrição (OpenGraph)"
        disabled={type === 'info'}
      />

      <label htmlFor="og_image">Url Imagem (OpenGraph)</label>
      <Input
        id="og_image"
        type="text"
        name="metadata.og_image"
        title="Url Imagem (OpenGraph)"
        placeholder="Url Imagem (OpenGraph)"
        disabled={type === 'info'}
      />

      <label htmlFor="twitter_title">Titulo (Twitter)</label>
      <Input
        id="twitter_title"
        type="text"
        name="metadata.twitter_title"
        title="Titulo (Twitter)"
        placeholder="Titulo (Twitter)"
        disabled={type === 'info'}
      />

      <label htmlFor="twitter_description">Descrição (Twitter)</label>
      <Input
        id="twitter_description"
        type="text"
        name="metadata.twitter_description"
        title="Descrição (Twitter)"
        placeholder="Descrição (Twitter)"
        disabled={type === 'info'}
      />
    </Container>
  );
};

export default FormMetadata;
